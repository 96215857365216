<template>
 
  <ion-content >
  <div id="container"  style="margin-left: 24px;margin-right: 24px;">
    <ion-row>
    <ion-col>
    </ion-col>
    <ion-col>
    </ion-col>
    <ion-col>
       <button type="button" @click="closeModal()" style="margin-left: 67%;margin-top: 6px;background-color: #fff;">
        <ion-icon :icon="close"></ion-icon> 
      </button>
    </ion-col>
  </ion-row>
    <ion-title> Agregar documento </ion-title>
    <ion-form>

      <ion-input type="text" id="user"  v-model="nombre_documento"  placeholder="Ingrese el nombre del documento" />
      
      <input type="file" ref="documentos" @change="getFile()" id="documento" style="display: none;">

      <div v-if="nombre_file != ''">
        <br>
        <ion-label style="float: left;margin-left: 10px; ">{{nombre_file}} </ion-label>
        <br>
        <br>
      </div>
      <ion-title>Selecciones los clientes</ion-title>
      <br>
      
      <div v-for="cliente in clientes" :key="cliente" align="left" style="margin-left: 12px">
        <p style="font-weight: bold;color: #000">
          <ion-checkbox slot="end" @update:modelValue="cliente.selected = $event" :modelValue="cliente.selected">
          </ion-checkbox> 
          &nbsp;&nbsp;{{cliente.name}}
        </p>
        <br>
      </div>
      
      <br>
      
      <ion-button color="dark" @click="file">
        Subir Documento
      </ion-button>

      <ion-button color="dark" @click="postDocumento">
        Enviar
      </ion-button>
    </ion-form>
  </div>
</ion-content>

</template>

<script>

import { defineComponent } from 'vue';
import { close } from 'ionicons/icons';
import { modalController,IonInput,IonCheckbox } from '@ionic/vue';
import axios from 'axios'
import toast from '@/plugins/toast'

export default defineComponent({
  name: 'AgregarColaborador',
  components :{IonInput,IonCheckbox},
  props : ['siniestro_id'],
  setup() {
    return { close }
  },
  data(){
    return{
      nombre_documento: null,
      documento: null,
      nombre_file : '',
      clientes_selected : [],
      clientes :[]
    }
  },
  mounted(){
    this.getClienteBySiniestro()
  },
  methods:{
    async closeModal() {
      const modal = await modalController
      return modal.dismiss();
    },
    event($event){
      this[$event.target.id] = $event.target.value
    },
    file(){
      document.querySelector('#documento').click()
    },
    getFile(){
      console.log(this.$refs.documentos.files)
      this.documento = this.$refs.documentos.files[0]
      this.nombre_file = 'Nombre del Archivo: '+  this.documento.name
      console.log(this.documento)
      console.log(this.documento.name)
    },
    getClienteBySiniestro(){
      axios.get('/api/users/clientes/bySiniestro/'+this.siniestro_id)
      .then(res => {
        console.log(res.data.data)
        var clientes = res.data.data

        clientes.forEach(cliente => {
          cliente.selected = true
        });
        
        this.clientes = clientes
      })
      .catch(error => {
        console.log(error)
      })
    },
    async postDocumento(){
      
      if (this.nombre_documento == null) {
        this.$toast.openToast("El nombre del documento es requerido","success")
        return;
      }
      
      var loading = await toast.showLoading()

      await loading.present();

      var formData = new FormData();
      
      formData.append('siniestro_id',this.siniestro_id)
      formData.append('nombre_documento',this.nombre_documento)
      formData.append('documento',this.documento)

      for (var i = 0; i < this.clientes.length; i++) {
        this.clientes[i].selected ? formData.append('cliente_ids[]',this.clientes[i].id) : null;
      } 

      axios.post('/api/documentos',formData)
      .then(data =>{
        loading.dismiss()
        this.closeModal()
        this.$toast.openToast("Registro de documento exitoso","success")
        console.log(data)
      }).catch(err =>{
        loading.dismiss()
        console.log(err)
      })
    }
  }
});
</script>

<style type="text/css">

 @media (min-width: 320px) and (max-width: 767px) {
    
    .popover-wide .popover-wrapper .popover-content {
      width: 90% !important;
      transform-origin: right top !important;
    }
    
  }  

.sc-ion-select-popover-h ion-list-header.sc-ion-select-popover, .sc-ion-select-popover-h ion-label.sc-ion-select-popover{
      text-align: center;
}
 @media (min-width: 768px) and (max-width: 1500px) {
    
    .popover-wide .popover-wrapper .popover-content {
      width: 38% !important;
      transform-origin: right top !important;
      text-align: right !important;
    }
    
  } 


ion-radio::part(container){
  display: none;
}
ion-select::part(text){

}

</style>

<style scoped>

ion-label {
  font-weight: bold;
}

ion-input {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-select {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
  height: 50px;
}

ion-textarea {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-button {
  width: auto;
  --border-radius: 20px;
  height: 35px;
  text-transform: none;
}
</style>