<template>
  <div style="z-index: 100000">
    <signature-pad
      @input="onInput"
      :modelValue="signatureFile"
      :width="550"
      :height="120"
      :customStyle="customStyle"
      :saveType="saveType"
      :saveOutput="saveOutput"
      :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()}}"
      ref="signaturePad"
    >
    </signature-pad>
    <ion-row>
      <ion-col>
        <ion-button color="dark" @click="showDocument()">
          Ver documento a firmar
        </ion-button>
      </ion-col>
      <ion-col>
        <ion-button color="dark" @click="clearSignature">
          Limpiar
        </ion-button>
      </ion-col>
      <!--<ion-col>
        <ion-button color="dark" @click="undoSignature">
          Deshacer
        </ion-button>
      </ion-col>-->
      <ion-col>
        <form :action="axios.defaults.baseURL+'/api/documentos/firma-documento'" >
          <input type="hidden" name="documento_id">
        </form>
        <ion-button color="dark" @click="saveSignature">
          Subir Firmar
        </ion-button>
      </ion-col>
    </ion-row>
  </div>
</template>

<script>

import { reactive, ref, toRefs } from "vue";
import SignaturePad from "vue3-signature-pad";
import axios from 'axios'
import toast from '@/plugins/toast'

export default {
  name: "Signature",
  props : ['documento'],
  components: {
    SignaturePad,
  },
  data(){
    return{
      axios,
      signatureDataURL :ref(null),
      signatureFile:ref(null),
      signaturePad :ref(null),
    }
  },
  mounted(){
    console.log(this.documento)
  },
  methods:{
   async saveSignature() {
      const signature = this.$refs.signaturePad.saveSignature();
      console.log(signature)
      
      var loading = await toast.showLoading()

      await loading.present();

      var formData = new FormData();

      formData.append('firma',signature.file)
      formData.append('documento_id',this.documento.id)
    
      axios.post('/api/documentos/firma-documento',formData)
      .then(data =>{
        loading.dismiss()
        this.$toast.openToast("Firma de documento exitoso","success")
        this.$router.go(0)
        console.log(data)
      }).catch(err =>{
        this.$router.go(0)
        loading.dismiss()
        console.log(err)
      })
      
    },
    showDocument(){
      console.log(this.documento)
      window.open(axios.defaults.baseURL+'/'+this.documento.path, "_blank"); 
    },
    clearSignature () {
      this.$refs.signaturePad.clearSignature();
    },
    undoSignature() {
       this.$refs.signaturePad.undoSignature();
    }
  },
  setup() {
   const state = reactive({
          customStyle: { border: "black 3px solid" },
          saveType: "image/png",
          saveOutput: "file",
        })
    return {
       ...toRefs(state),
    };
  },
};
</script>


<style scoped>

ion-label {
  font-weight: bold;
}

ion-input {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-button {
  width: auto;
  --border-radius: 20px;
  height: 35px;
  text-transform: none;
}

.input-text{
  margin-bottom: 15px;
  padding-left: 17px;
  background: #F5F5F5;
  border: 1px solid rgba(183, 183, 183, 0.3);
  box-sizing: border-box;
  border-radius: 24px;
  width :90%;
  height : 48px;
}

.selected{
  background-color: #bac1eb !important;
}
</style>