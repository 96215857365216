<template>
<ion-content class="ion-padding"> 
	<div style="text-align: center;">Detalles del siniestro</div>
	<div>
		<ion-row>
			<br>
			<template v-for="(cliente,key) in siniestro.clientes" :key="cliente">
				<ion-col size="8"><ion-label> Nombre del afectado {{key+1}}: </ion-label></ion-col>
				<ion-col size="4">
					{{cliente.name}}
				</ion-col>
				<ion-col size="8"><ion-label> Movil del afectado {{key+1}}: </ion-label></ion-col>
				<ion-col size="4">
					{{cliente.movil}}
				</ion-col>
				<ion-col size="8"><ion-label> Teléfono del afectado {{key+1}}: </ion-label></ion-col>
				<ion-col size="4">
					{{cliente.telefono}}
				</ion-col>
				<div class="hr"> </div>
			</template>
			
			<ion-col size="4"><ion-label> Expediente </ion-label></ion-col>
			<ion-col v-if="siniestro.expediente == null">
              Sin nro. expediente
            </ion-col>
            <ion-col v-else>
             {{siniestro.expediente}}
            </ion-col>
            
            <div class="hr"></div>
			
			<ion-col size="4"><ion-label> Observación </ion-label></ion-col>
			<ion-col size="8">
				{{siniestro.observacion}}
			</ion-col>
			<div class="hr"> </div>
			<ion-col size="4"><ion-label> Versión Suceso </ion-label></ion-col>
			<ion-col size="8">
				{{siniestro.version_suceso}}
			</ion-col>
		</ion-row>
	</div> 
</ion-content>
</template>

<script>
  import { IonContent, IonLabel } from '@ionic/vue';
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'Popover',
    props: ['siniestro'],
    components: { IonContent, IonLabel },
mounted(){
console.log(this.siniestro)
}
  });
</script>

<style type="text/css">
	.hr {
  border-bottom: 1px solid #ccc;
  margin: 10px 0;
  width: 100%;
}
</style>