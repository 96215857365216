<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ $route.meta.title }} </ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ $route.meta.title }} </ion-title>
        </ion-toolbar>
      </ion-header>
    
      <div id="container">
        <ion-row>
          <ion-col>
             <svg style="z-index: 50;float: left;margin-left: 25px;" @click="$router.go(-1)" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path d="M27 16H5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14 7L5 16L14 25" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

          </ion-col>
          <ion-col></ion-col>
          <ion-col>  
            <!--
            <ion-button v-if="getUser.role.name == 'Despacho'" color="dark" style="float: right;    margin-right: 65px; width: 45px;" @click="openModalAdd">
              +
            </ion-button>
            -->
            <ion-button v-if="getUser.role.name == 'Despacho'" color="dark" style="float: right;    margin-right: 65px; width: 45px;" @click="addDocument">
              +
            </ion-button>
            
          </ion-col>
        </ion-row>
        <div style="margin-left: 24px;margin-right: 24px;">
          <div class="card">
            <ion-title size="large">
              <template v-for= "(cliente,key) in siniestro?.clientes" :key="cliente">
                {{cliente.name}}<template v-if="siniestro?.clientes.length != (key+1)">&nbsp;- </template>
              </template> 
               <ion-icon @click="openPopover" style="margin-top: 7px; float: right;" :icon="bookmarks" :ios="bookmarksOutline" :md="bookmarks"></ion-icon> 
            </ion-title>
              <table class="table table-responsive-sm table-striped table-align-middle">

                <thead>
                  <tr>
                    <th>Nombre del Documento</th>
                    <th  v-if="getUser.role.name == 'Cliente'">¿Esta firmado?</th>
                    <th v-if="getUser.role.name == 'Cliente'" colspan="2">
                     
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="documento in documentos.data" :key="documento">
                    <td>
                        {{documento?.nombre_documento}} 
                    </td>
                    <td  v-if="getUser.role.name == 'Cliente'"> {{ documento.firmado == 1 ? 'Si' : 'No' }}
                    </td>
                    <td  v-if="getUser.role.name == 'Cliente'">
                      <div>
                        <template v-if="!documento.firmado">
                          <!--<ion-button color="primary" @click="showSign(documento)">
                            Agregar Firmar
                          </ion-button>-->
                          <ion-button color="primary" @click="sign_document(documento)">
                            Agregar Firmar
                          </ion-button>
                        </template>
                        
                      </div>
                      </td>
                      <td >
                        <div >
                          <ion-button color="primary" @click="show_document(documento)" >
                            Ver documento
                          </ion-button>
                        </div>
                      </td>
                      <td v-if="getUser.role.name == 'Despacho'">
                      <!--<div>
                        <ion-button color="primary" @click="download(documento)" >
                          Descargar documentos firmados
                        </ion-button>
                      </div>-->
                      <div v-if="getUser.role.name == 'Despacho'">
                        <ion-button color="primary" @click="getClienteDocumentos(documento)" >
                          Ver Detalles
                        </ion-button>
                      </div>
                    </td>
                    <td>
                       <div v-if="getUser.role.name == 'Despacho'">
                        <ion-button color="primary" @click="downloadDocumento(documento)" >
                          Descargar comprimido
                        </ion-button>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="documentos?.data?.length == 0"> 
                    <td colspan="3">
                      Actualmente no hay documentos registrado en este siniestro
                    </td>
                  </tr>
                </tbody>
              </table>
              <nav aria-label="page navigation example">
                <ul class="pagination justify-content-center">
                  <li  :class="{'page-item' : true , 'disabled' : !documentos.prev_page_url }">
                    <a class="page-link"   @click="page(documentos.prev_page_url)" tabindex="-1">Anterior</a>
                  </li>
                  <li class="page-item"><a class="page-link">{{documentos.current_page}}</a></li>
                  <li :class="{'page-item' : true , 'disabled' : !documentos.next_page_url }">
                    <a class="page-link"  @click="page(documentos.next_page_url)">Siguiente</a>
                  </li>
                </ul>
              </nav>
            </div>
          
          <!--
            <center v-if="sign && getUser.role.name == 'Cliente'">
              <ion-title>
                Nombre del documento: {{documento?.nombre_documento}}</ion-title>
              <br>
              <signature :documento="documento" @refresh="getDocumentos()"></signature>
            </center>
          -->
          
          <div v-if="show_documents">
            <table class="table table-responsive-sm table-striped table-align-middle">

              <thead>
                <tr>
                  <th>Cliente</th>
                  <th>Estatus</th>
                  <th>Acciones</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="cliente_documento in cliente_documentos" :key="cliente_documento">
                  <td>{{cliente_documento.user.name}}</td>
                  <td>{{cliente_documento.firmado ? "Firmado" : "No firmado"}}</td>
                  <td> 
                    <ion-button color="primary" @click="show_document(cliente_documento)" >
                      Descargar documento
                    </ion-button>
                  </td>
                </tr>
                <tr v-if="cliente_documentos?.length == 0"> 
                  <td colspan="3">
                    Actualmente no hay documentos registrado en este siniestro
                  </td>
                </tr>
              </tbody>
            </table>  
          </div>
          <br><br><br>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/vue';
import { modalController,createAnimation,popoverController } from '@ionic/vue';
import { mapGetters } from "vuex";
import axios from 'axios'
import agregarDocumentoModal from './agregarDocumentoModal';
import Firmar from './firmar';
import toast from '@/plugins/toast'
import { bookmarksOutline, bookmarks } from 'ionicons/icons'; 
//import signature from './signature'
import PopoverSiniestro from '../chat/PopoverSiniestro'

export default defineComponent({
  name: 'FolderPage',
  components: {
    //signature,
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar
  },
  computed : {
    ...mapGetters([
        'getUser'
    ]),
  },
  data(){
    return{
      axios,
      documentos : [],
      documento : null,
      siniestro_id : null,
      siniestro : null,
      sign : false,
      show_documents : false,
      cliente_documentos: []
    }
  },
  setup(){
    
    return {  
      bookmarksOutline,
      bookmarks,
    }
  },
  mounted(){
    this.siniestro_id = this.$route.params.siniestro_id
    this.getSiniestro()
    this.getDocumentos()
    this.setStatusNotifier()
    if (this.$route.query.documento_id !== undefined ) {
      //this.getDocumento(this.$route.query.documento_id)
    }

},
  methods:{
    async openPopover(ev) {
      
      const popover = await popoverController.create({
        component: PopoverSiniestro,
        cssClass: 'class',
        event: ev,
        translucent: false,
        showBackdrop : false,
        componentProps : {siniestro : this.siniestro}
      });
      
      await popover.present();

      const { role } = await popover.onDidDismiss();
      
      console.log('onDidDismiss resolved with role', role);
    },
    setStatusNotifier(){
      axios.post('/api/siniestros/set/status/notifier',{
        siniestro_id : this.siniestro_id,
        user_id : this.getUser.id
      })
      .then(res => {
        res
        console.log("OK")
      })
      .catch(error => {
        //toast.openToast("Ha ocurrido un error","error",2000)
        console.log(error);
      });
    },
    getClienteDocumentos(documento){
      this.show_documents = true
      axios.get('/api/documentos/cliente_documentos/'+documento.id)
      .then(res => {
        this.cliente_documentos = res.data.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    getDocumentos(){
       axios.get('/api/documentos/'+this.siniestro_id)
      .then(res => {
        console.log(res.data.data)
        this.documentos = res.data.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    getDocumento(documento_id){
       axios.get('/api/documentos/'+documento_id+'/details')
      .then(res => {
        console.log(res.data)
        var documento = res.data.data
        this.showSign(documento)
      })
      .catch(error => {
        console.log(error)
      })
    },
    getColaboradoresByUser(){
       axios.get('/api/users/colaborador/byUser')
      .then(res => {
        console.log(res.data.data)
        this.colaboradores = res.data.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    getSiniestro(){
      axios.get('/api/siniestros/'+this.siniestro_id)
      .then(res => {
        this.siniestro = res.data.data;
        console.log(this.siniestro)
      })
      .catch(error => {
        //toast.openToast("Ha ocurrido un error","error",2000)
        console.log(error);
      });
    },
    downloadDocumento(documento){
      
      if (documento.documento_firmados.length == 0 ) {
        toast.openToast("Los cliente aun no han firmado los documentos","error",2000)
        return
      }

      window.open(axios.defaults.baseURL+"/api/documentos/download-zip/"+documento.id, '_blank');
    },
    page(url){
      axios.get(url)
       .then(res => {
         this.colaboradores = res.data.data;
       })
       .catch(error => {
        //toast.openToast("Ha ocurrido un error","error",2000)
        console.log(error);
      });
    },
    showSign(documento){
      this.sign = true
      this.documento = documento;
    },
    showClienteByColaborador(colaborador){
      this.$router.push({name : 'cliente_coloborador',params : {id:colaborador.id}})
    },
    show_document(documento){
       window.open(axios.defaults.baseURL+'/'+documento.path, "_blank"); 
    },
    sign_document(documento){
      location.href = '/firmar/'+documento.id
    },
    download(documento){
      if (documento.documento_firmados.length == 0 ) {
        toast.openToast("Los cliente aun no han firmado los documentos","error",2000)
        return
      }

      for (var i = 0; i < documento.documento_firmados.length; i++) {
        //var fileLink = document.createElement('a');
        //fileLink.setAttribute('download', i+'_'+documento.nombre_documento);
        //fileLink.setAttribute('target', '_black');
        //fileLink.href =  axios.defaults.baseURL+'/'+documento.documento_firmados[i].pivot.path;
        window.open(axios.defaults.baseURL+'/'+documento.documento_firmados[i].pivot.path, "_blank"); 
        //document.body.appendChild(fileLink);
        //fileLink.click();
      }
    },
    async openModalAdd() {
    
      const modal = await modalController
        .create({
          component: agregarDocumentoModal,
          cssClass: 'class-modal',
          keyboardClose : true,
          enterAnimation: this.enterAnimation,
          leaveAnimation: this.leaveAnimation,
          componentProps : {siniestro_id: this.siniestro_id}
        })
  
      modal.present();

      modal.onDidDismiss().then((data) => {
        console.log(data)
        this.getColaboradoresByUser()
        this.getDocumentos()
      })

    },
    addDocument(){
      location.href = "/agregar-documento/"+this.siniestro_id
    },
    async openModalFirma(documento) {
    
      const modal = await modalController
        .create({
          component: Firmar,
          cssClass: 'class-modal',
        keyboardClose : true,
          enterAnimation: this.enterAnimation,
          leaveAnimation: this.leaveAnimation,
          componentProps : {documento : documento}
        })


   
      modal.present();
      modal.onDidDismiss().then((data) => {
        console.log(data)
        this.getColaboradoresByUser()
    this.getDocumentos()
      })

    },
     enterAnimation : function () {
      let baseEl = document
        const backdropAnimation = createAnimation()
        .addElement(baseEl.querySelector('ion-backdrop'))
        .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

      const wrapperAnimation = createAnimation()
        .addElement(baseEl.querySelector('.modal-wrapper'))
        .keyframes([
          { offset: 0, opacity: '0', transform: 'scale(0)' },
          { offset: 1, opacity: '0.99', transform: 'scale(1)' }
        ]);

      return createAnimation()
            .addElement(baseEl)
            .easing('ease-out')
            .duration(500)
            .addAnimation([backdropAnimation, wrapperAnimation]);
    },
    leaveAnimation  : function () {
       return this.enterAnimation(document).direction('reverse');
    },
  }
});
</script>

<style scoped>

ion-label {
  font-weight: bold;
}

ion-input {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-button {
  width: auto;
  --border-radius: 20px;
  height: 35px;
  text-transform: none;
}

.input-text{
  margin-bottom: 15px;
  padding-left: 17px;
  background: #F5F5F5;
  border: 1px solid rgba(183, 183, 183, 0.3);
  box-sizing: border-box;
  border-radius: 24px;
  width :90%;
  height : 48px;
}

.selected{
  background-color: #bac1eb !important;
}
</style>